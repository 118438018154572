export default [
    {
        title: 'Was ist die NEW-Laufserie 2025? ',
        content: 'Die NEW-Laufserie ist eine Reihe von fünf Laufevents in der Region, bei denen Läufer:innen an 10-km-Läufen teilnehmen können. Die Serie beginnt am 01.03.2025 und endet am 15.06.2025. ',
    },
    {
        title: 'Wie kann ich mich anmelden? ',
        content: '<p>Man muss sich für jeden der 10-km-Läufe direkt beim jeweiligen Veranstalter anmelden.</p>' +
            '<ul>' +
            '<li><a target="_blank" href="https://www.hkl-mg.de/anmeldung/">01.03.2025: Hardter Karnevalslauf</a></li>' +
            '<li><a target="_blank" href="https://citylauf-korschenbroich.com/anmeldung/">06.04.2025: Korschenbroicher City-Lauf</a></li>' +
            '<li><a target="_blank" href="https://www.apfelbluetenlauf.de/anmeldung/">27.04.2025: Apfelblütenlauf</a></li>' +
            '<li><a target="_blank" href="https://rahser-run.de/">18.05.2025: Ra(h)ser-Run</a></li>' +
            '<li><a target="_blank" href="https://www.tv-erkelenz.de/">15.06.2025: NEW-Citylauf Erkelenz</a></li>' +
            '</ul><br>' +
            '<p>Zusätzlich muss man sich über das Anmeldeformular auf unserer Seite <a target="_blank" href="https://www.new.de/laufserie#anmeldung">NEW Laufserie 2025</a> für die gesamte Laufserie registrieren.</p>'
    },
    {
        title: 'Was sind die Teilnahmebedingungen?',
        content: 'Um in die Gesamtwertung zu kommen, musst du an mindestens drei von fünf Läufen teilnehmen. Dabei ist es egal, ob du Bestzeiten läufst oder einfach nur Spaß haben möchtest.'
    },
    {
        title: 'Gibt es besondere Vorteile für frühzeitige Anmelder:innen?',
        content: 'Ja, die ersten 50 Teilnehmer:innen, die sich für die NEW-Laufserie anmelden, erhalten ein exklusives NEW-Starter-Pack. Alle weiteren Teilnehmer:innen bekommen ein Laufshirt.'
    },
    {
        title: 'Wann und wo findet die Siegerehrung statt?',
        content: 'Die Siegerehrung erfolgt beim letzten Lauf, dem NEW-Citylauf in Erkelenz am 15.06.2025, auf der NEW-Bühne.'
    },
    {
        title: 'Gibt es Preise für die Teilnehmer?',
        content: 'Ja, neben Geldpreisen für die schnellsten Läufer gibt es auch eine Verlosung von tollen Preisen, an der alle Teilnehmer automatisch teilnehmen. Zu gewinnen gibt es unter anderem Tickets für die Borussia-Loge.'
    },
    {
        title: 'Bis wann kann ich mich anmelden?',
        content: 'Du kannst dich bis zum dritten Lauf der Serie (Apfelblütenlauf am 27.04.2025) registrieren.'
    },
    {
        title: 'Für wen ist die NEW-Laufserie geeignet?',
        content: 'Die NEW-Laufserie ist für alle geeignet, ob Anfänger:in oder erfahrene:r Läufer:in. Es geht um den gemeinsamen Spaß am Laufen und die besondere Atmosphäre der Events.'
    }
]
